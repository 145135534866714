import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import Loader from 'react-loading-components';

import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Loading from '../../Components/Loading';
import ConfirmModal from '../../Components/ConfirmModal';

import Api from '../../Services/Api';
import { parseMoney, getShift } from '../../Util/Functions';

import { isAuthenticated } from '../../Services/Authentication';

import './index.css'
import { toast } from 'react-toastify';

export default class Buy extends Component {

    constructor(props) {
        super(props)

        this.state = {
            termAccepted: false,
            loading: true,
            scholarship: {},
            indications: {},
            scholarshipId: null,
            creditCardSelected: false,
            boletoSelected: false,
            pixSelected: false,
            freeCourse: false,
            requestLoading: false,
            paymentAuthorized: false,
            paymentAnalysis: false,
            paymentRejected: false,
            boletoConfirmModalOpen: false,
            boletoUrl: '',
            pixUrl: '',
            scholarship_not_avaliable: false
        }
    }

    async componentDidMount() {
        this.getData();
        this.setInterest();
    }

   setInterest = async () => {
        try {
            let response = await Api.post('/payment/create-interest-payment', { scholarship_id: this.props.match.params.bolsa });
            console.log(response);
        } catch (error) {
            console.log('error');
            console.log(error.response);
        }
    }   

    getData = async () => {
        this.setState({ scholarshipId: this.props.match.params.bolsa });
        try {

            // temporariamente até entender como será a logica de renew
            let endpoint = false ? '/scholarship/renew/' : '/scholarship/';
            let scholarshipRequest = await Api.get(endpoint + this.props.match.params.bolsa);
            if (!scholarshipRequest.data.scholarship) return this.setState({ scholarship_not_avaliable: true, loading: false });

            let indicationsRequest = await Api.get('indications/user-indications');
            this.setState({ loading: false, indications: indicationsRequest.data.indications, scholarship: scholarshipRequest.data.scholarship })

            //stop
                //criar tabela de indicações backend
                //criar rota de criar indicações backend
                //criar tela de cadastro de indicações cliente no cliente -> realizar melhor validação de nome e telefone possivel
                //criar tela de consulta de indicações cliente no admin -> criar opção de já entrou em contato e observações assim como no possible partner
                //criar rota para buscar total de indicações backend

                //this.state.scholarship.liberado_por_qtd_indicacao != null && this.state.scholarship.liberado_por_qtd_indicacao > 0 ?
                //fazer consulta para ver total de indicações
                //caso o total seja menor que o solicitado, marcar uma variavel como false, senão, como true
                //realizar validação dessa variavel no outro stop
                //caso verdadeiro, liberar para comprar o curso
                //caso falso, redirecionar para a tela de indicações

            //criar tabela de cadastro das perguntas e respostas baseado no scholarship no backend
            //criar tela de crud de perguntas e respostas no admin
            //criar tela de realização do curso no cliente
            //criar botão de acesso ao curso pelo painel através do curso que tiver essa opção no cliente
            //obs: talvez exista necessidade de alterar query existente que busca os cursos no painel do cliente

            //ao concluir o curso acima de % de acertos indicados, emitir diploma que tenha replace para o nome do curso e do aluno
        } catch (error) {
            console.log(error);
        }
    }

    toggleBoleto = () => {
        console.log('toggleBoleto')
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ boletoConfirmModalOpen: true });
    }

    confirmPix = async () => {
        console.log('confirmPix')
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ pixSelected: true, creditCardSelected: false, requestLoading: true, boletoConfirmModalOpen: false, boletoSelected: false });
        try {
            let paymentRequest = await Api.post('/payment/gn/pix', { scholarship_id: this.props.match.params.bolsa, renew: false });
            this.setState({ requestLoading: false })
            console.log(paymentRequest);
            let paymentResponse = paymentRequest.data;
            if (paymentResponse.ok && paymentResponse.ok === true) {
                console.log(paymentResponse)
                this.setState({ requestLoading: false, pixUrl: paymentResponse.payment.url_boleto });
            } else {
                if (paymentResponse.userMessage) return toast.error(paymentResponse.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                if (paymentResponse.message) return toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
                toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
            }

        } catch (error) {
            console.log('error');
            this.setState({ requestLoading: false })
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    confirmBoleto = async () => {
        console.log('confirmBoleto')
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ boletoSelected: true, creditCardSelected: false, requestLoading: true, boletoConfirmModalOpen: false });
        try {
            let paymentRequest = await Api.post('/payment/gn/boleto', { scholarship_id: this.props.match.params.bolsa, renew: false });
            this.setState({ requestLoading: false })
            let paymentResponse = paymentRequest.data;
            if (paymentResponse.ok && paymentResponse.ok === true) {
                console.log(paymentResponse)
                this.setState({ requestLoading: false, boletoUrl: paymentResponse.payment.url_boleto });
            } else {
                if (paymentResponse.userMessage) return toast.error(paymentResponse.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                if (paymentResponse.message) return toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
                toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
            }

        } catch (error) {
            console.log('error');
            this.setState({ requestLoading: false })
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    confirmBoletoCursoGratis = async () => {
        console.log('confirmBoletoCursoGratis')
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ freeCourse: true, creditCardSelected: false, requestLoading: true, boletoConfirmModalOpen: false });
        try {
            let paymentRequest = await Api.post('/payment/gn/boleto/curso-gratis', { scholarship_id: this.props.match.params.bolsa, renew: false });
            this.setState({ requestLoading: false })
            let paymentResponse = paymentRequest.data;
            if (paymentResponse.ok && paymentResponse.ok === true) {
                console.log(paymentResponse)
                this.setState({ requestLoading: false, boletoUrl: paymentResponse.payment.url_boleto });
            } else {
                if (paymentResponse.userMessage) return toast.error(paymentResponse.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                if (paymentResponse.message) return toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
                toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
            }

        } catch (error) {
            console.log('error');
            this.setState({ requestLoading: false })
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    toggleCreditCart = () => {
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ boletoSelected: false, creditCardSelected: true });
    }

    redirectCadastroIndicacoes = () => {
        this.props.history.push('/indicacoes');
    }

    handleGnCreditCardSubmit = async (e) => {
        e.preventDefault();
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ requestLoading: true });
        var formData = new FormData(document.getElementById('creditCardForm'));
        var creditCardData = {};
        formData.forEach((value, key) => { creditCardData[key] = value });
        try {
            const ip = await fetch('https://api.ipify.org?format=json').then(response => response.json()).then(data => data.ip);                
            var payment = {
                scholarship_id: this.props.match.params.bolsa,
                renew: false,
                installment_count: creditCardData.installment_count,
                ip: ip,
                creditCard: {
                    holderName: creditCardData.ccname,
                    number: creditCardData.cardNumber,
                    ccv: creditCardData.cvc,
                    expiryMonth: creditCardData.ccExp.split('/')[0],
                    expiryYear: '20' + creditCardData.ccExp.split('/')[1]
                },
                creditCardHolderInfo: {
                    name: creditCardData.ccname,
                    cpfCnpj: creditCardData.cpf,
                    birthdate: creditCardData.birthdate,
                    phone: creditCardData.phone
                }
            }
            let paymentRequest = await Api.post('/payment/gn/credit_card', payment);
            this.setState({ requestLoading: false })

            let paymentResponse = paymentRequest.data;
            
            console.log(paymentRequest);
            if (paymentResponse.ok && paymentResponse.ok === true) {
                if (paymentResponse.payment.status === 'PAID') {
                    this.setState({ paymentAuthorized: true, paymentAnalysis: false, paymentRejected: false, boletoSelected: false, creditCardSelected: false })
                }
                else if (paymentResponse.payment.status === 'WAITING') {
                    this.setState({ paymentAnalysis: true, paymentRejected: false, paymentAuthorized: false, boletoSelected: false, creditCardSelected: false });
                }
                else if (paymentResponse.payment.status === 'CANCELLED' || paymentResponse.payment.status === 're') {
                    this.setState({ paymentRejected: true, paymentAuthorized: false, paymentAnalysis: false, boletoSelected: false, creditCardSelected: false });
                }
                else if (paymentResponse.payment.status === 'IN_ANALYSIS' || paymentResponse.payment.status === 're') {
                    this.setState({ paymentAnalysis: true, paymentRejected: false, paymentAuthorized: false, boletoSelected: false, creditCardSelected: false });
                }
            } else {
                if (paymentResponse.userMessage) return toast.error(paymentResponse.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                if (paymentResponse.message) return toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
                toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
            }

        } catch (error) {
            console.log('error');
            this.setState({ requestLoading: false })
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.error && error.error === 'invalid_expiration_month') return toast.error('Data de validade do cartão inválida');
            if (error.error && error.error === 'invalid_expiration_year') return toast.error('Data de validade do cartão inválida');
            if (error.error_description) return toast.error(error.error_description);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }

    }

    handleCreditCardSubmit = async (e) => {
        e.preventDefault();
        if (!document.getElementById('confirm_terms').checked) return alert('Você precisa aceitar os termos.')
        this.setState({ requestLoading: true });
        try {
            var formData = new FormData(document.getElementById('creditCardForm'));
            var creditCardData = {};
            formData.forEach((value, key) => { creditCardData[key] = value });
            let payment = {
                scholarship_id: this.props.match.params.bolsa,
                payment_type: 'credit_card',
                renew: false,
                installment_count: creditCardData.installment_count,
                creditCard: {
                    number: creditCardData.cardNumber,
                    cvc: creditCardData.cvc,
                    expirationMonth: creditCardData.ccExp.split('/')[0],
                    expirationYear: creditCardData.ccExp.split('/')[1]
                },
                holder: {
                    name: creditCardData.ccname,
                    birthdate: creditCardData.birthdate,
                    cpf: creditCardData.cpf,
                    phone: creditCardData.phone
                }
            }
            let paymentRequest = await Api.post('/payment/credit_card', payment);
            this.setState({ requestLoading: false })
            let paymentResponse = paymentRequest.data;
            console.log(paymentResponse);
            if (paymentResponse.ok && paymentResponse.ok === true) {
                if (paymentResponse.status === 'AUTHORIZED') {
                    this.setState({ paymentAuthorized: true, paymentAnalysis: false, paymentRejected: false, boletoSelected: false, creditCardSelected: false })
                }
                else if (paymentResponse.status === 'IN_ANALYSIS' || paymentResponse.status === 'WAITING') {
                    this.setState({ paymentAnalysis: true, paymentRejected: false, paymentAuthorized: false, boletoSelected: false, creditCardSelected: false });
                }
                else if (paymentResponse.status === 'CANCELLED' || paymentResponse.status === 'REJECTED') {
                    this.setState({ paymentRejected: true, paymentAuthorized: false, paymentAnalysis: false, boletoSelected: false, creditCardSelected: false });
                }
            } else {
                if (paymentResponse.userMessage) return toast.error(paymentResponse.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
                if (paymentResponse.message) return toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
                toast.error(paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
            }

        } catch (error) {
            console.log('error');
            this.setState({ requestLoading: false })
            if (error.response && error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage, { position: toast.POSITION.BOTTOM_RIGHT });
            if (error.response && error.response.data && error.response.data.message) return toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT });
            console.log(error.response);
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    
    

    render() {
        if (!isAuthenticated()) return <Redirect to={"/Login?" + this.props.match.params.bolsa} />
        if (this.state.loading) return <div className="container-fluid"><Header /><Loading /><Footer /></div>
        if (this.state.scholarship_not_avaliable) return <div className="container-fluid"><Header /><div>Esta bolsa não está disponivel</div><Footer /></div>
        
        
        return (
            <div className="container-fluid">
                <Header />
                <div className="row d-flex px-3 mx-lg-5 px-lg-5 justify-content-between mb-4">
                    <div className="col-md-10 mt-4 mb-3">
                        <h3>Inscrição</h3>
                    </div>
                    <div className="col-md-9 pr-md-5 mb-3">
                        <div className="row">
                            <div className="col-12 p-0 info-box mb-3">
                                <div className="scholarship-details-header bg-primary p-1 px-2">
                                    <span className="text-white">Detalhes da sua bolsa</span>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-md-8 d-flex align-items-center">
                                            <table>
                                                <tr>
                                                    <td className="pr-3">Curso / Série:</td>
                                                    <td><b>{this.state.scholarship.course.name}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Instituição:</td>
                                                    <td><b>{this.state.scholarship.institution.name}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Turno:</td>
                                                    <td><b className="capitalize">{getShift(this.state.scholarship.shift)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Modalidade:</td>
                                                    <td><b className="capitalize">{this.state.scholarship.type}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Bolsa de:</td>
                                                    <td><b className="text-value text-secondary">{this.state.scholarship.discount}%</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Mensalidade:</td>
                                                    <td>
                                                        <b className="text-value text-secondary">{parseMoney(this.state.scholarship.final_price)}</b>
                                                        <strike className="ml-3 scholarship-full-price">{parseMoney(this.state.scholarship.full_price)}</strike>
                                                    </td>
                                                </tr>
                                                {/*
                                                <tr>
                                                    <td colSpan="2" className="py-1"><i className="fa fa-gift mr-1 text-secondary"></i><b>Aproveite seu desconto de 50% na matrícula!</b></td>
                                                </tr>
                                                */}
                                            </table>
                                        </div>
                                        {this.state.scholarship.liberado_por_qtd_indicacao != null && this.state.scholarship.liberado_por_qtd_indicacao > this.state.indications.length ?
                                            <div className="col-md-4 mt-3 mt-md-0 text-center">
                                                <h5>Curso não liberado por falta de indicações</h5>
                                                <button
                                                    disabled={this.state.requestLoading}
                                                    onClick={this.redirectCadastroIndicacoes}
                                                    className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center">
                                                    <span className="ml-2 mt-1">Cadastrar indicações</span>
                                                </button>
                                            </div>
                                        :
                                            this.state.scholarship.final_price === 0 ?
                                                <div className="col-md-4 mt-3 mt-md-0 text-center">
                                                    <h5>Como deseja pagar?</h5>
                                                    <button
                                                        disabled={this.state.requestLoading}
                                                        onClick={this.confirmBoletoCursoGratis}
                                                        className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center">
                                                        <span className="ml-2 mt-1">Adiquirir Curso</span>
                                                    </button>
                                                </div>
                                            :
                                                <div className="col-md-4 mt-3 mt-md-0 text-center">
                                                    <h5>Como deseja pagar?</h5>
                                                    <button
                                                        disabled={this.state.requestLoading}
                                                        onClick={this.toggleBoleto}
                                                        className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center">
                                                        <span className="ml-2 mt-1">Boleto</span>
                                                    </button>
                                                    <button
                                                        onClick={this.toggleCreditCart}
                                                        disabled={this.state.requestLoading}
                                                        className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center">
                                                        <span className="ml-2 mt-1">Cartão de crédito</span>
                                                    </button>
                                                    <button
                                                        onClick={this.confirmPix}
                                                        disabled={this.state.requestLoading}
                                                        className="btn btn-secondary btn-block btn-buy-scholarship text-white d-flex align-items-center justify-content-center">
                                                        <span className="ml-2 mt-1">Pix</span>
                                                    </button>
                                                </div>
                                        }
                                        <div className="col-12">
                                            <div className="form-group ml-4 mt-2">
                                                <input type="checkbox" class="form-check-input" id="confirm_terms" required />
                                                <label class="form-check-label" for="confirm_terms">Confirmo que não tenho vínculo com a instituição de ensino há pelo menos um ano e aceito os <a class="link-azul" href="termos-uso">termos de uso</a>.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={this.state.paymentAuthorized}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Pagamento com cartão de crédito</span>
                                    </div>
                                    <div className="col-12 flex-column bg-light d-flex justify-content-center align-items-center p-5">
                                        <h3>Seu pagamento foi aprovado!</h3>
                                        <div><a href={"/cupom?bolsa=" + this.state.scholarshipId} className="text-secondary" rel="noopener noreferrer" target="_blank">Clique aqui</a> para acessar o seu cupom</div>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.paymentAnalysis}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Pagamento com cartão de crédito</span>
                                    </div>
                                    <div className="col-12 flex-column bg-light d-flex justify-content-center align-items-center p-5">
                                        <h3>Seu pagamento está sendo processado!</h3>
                                        <span>Você receberá um email quando o pagamento for confirmado.</span>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.paymentRejected}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Pagamento com cartão de crédito</span>
                                    </div>
                                    <div className="col-12 flex-column bg-light d-flex justify-content-center align-items-center p-5">
                                        <h3>Seu pagamento foi recusado!</h3>
                                        <span>Revise os dados de pagamento, ou tente pagar com um cartão diferente</span>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.boletoSelected}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Pagar com boleto</span>
                                    </div>
                                    <div className="row d-flex justify-content-center p-2 p-md-3">
                                        {this.state.requestLoading
                                            ?
                                            <Loader type='tail_spin' width={40} height={40} fill='#14496b' />

                                            :
                                            <div className="m-3">
                                                <h3>Pronto</h3>
                                                <a href={this.state.boletoUrl} target="_blank" rel="noopener noreferrer" className="text-secondary">Clique aqui</a><span> para acessar o seu boleto, este link tambem será enviado para o seu email, verifique sua caixa de SPAM.</span>
                                            </div>}
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.freeCourse}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Curso adquirido comn sucesso</span>
                                    </div>
                                    <div className="row d-flex justify-content-center p-2 p-md-3">
                                        {this.state.requestLoading
                                            ?
                                            <Loader type='tail_spin' width={40} height={40} fill='#14496b' />

                                            :
                                            <div className="m-3">
                                                <h3>Pronto</h3>
                                                <span>
                                                    Para acessar o seu curso, vá para o{' '}
                                                    <Link to="/minha_conta" className="text-secondary">
                                                        Painel
                                                    </Link>
                                                    .
                                                </span>
                                            </div>}
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.pixSelected}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Pagar com Pix</span>
                                    </div>
                                    <div className="row d-flex justify-content-center p-2 p-md-3">
                                        {this.state.requestLoading
                                            ?
                                            <Loader type='tail_spin' width={40} height={40} fill='#14496b' />

                                            :
                                            <div className="m-3">
                                                <h3>Pronto</h3>
                                                <a href={this.state.pixUrl} target="_blank" rel="noopener noreferrer" className="text-secondary">Clique aqui.</a>
                                            </div>}
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={this.state.creditCardSelected}>
                                <div className="col-12 p-0 info-box">
                                    <div className="scholarship-details-header bg-primary p-1 px-2">
                                        <span className="text-white">Pagamento com cartão de crédito</span>
                                    </div>
                                    <form action="" method="POST" className="row p-2 p-md-3" id="creditCardForm" onSubmit={this.handleGnCreditCardSubmit}>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input placeholder="Número do cartão" type="text" name="cardNumber" className="form-control" required />
                                            </div>
                                            <div className="row">
                                                <div className="col-7">
                                                    <div className="form-group">
                                                        <MaskedInput
                                                            mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                                                            className="form-control"
                                                            name="ccExp"
                                                            placeholder="Data de validade"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="CVC" name="cvc" className="form-control input-cvc" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <h4>Dados do titular do cartão</h4>
                                            <div className="form-group">
                                                <input placeholder="Nome completo" type="text" name="ccname" className="form-control" required />
                                            </div>
                                            <div className="form-group">
                                                <MaskedInput
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    className="form-control"
                                                    name="birthdate"
                                                    placeholder="Data de nascimento"
                                                    required
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <MaskedInput
                                                            mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                            className="form-control"
                                                            name="phone"
                                                            placeholder="Telefone"
                                                            defaultValue={this.state.phone ? this.state.phone : ""}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <MaskedInput
                                                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                                                    className="form-control"
                                                    name="cpf"
                                                    placeholder="CPF"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select name="installment_count" className="form-control" required>
                                                    <option disabled selected value="">Número de parcelas</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                            <div className="row">
                                                {this.state.requestLoading ?
                                                    (
                                                        <div className="col-12 d-flex align-items-center justify-content-center">
                                                            <Loader type='tail_spin' width={40} height={40} fill='#14496b' />
                                                        </div>
                                                    ) :
                                                    (
                                                        <div className="col-lg-4">
                                                            <button type="submit" className="btn btn-secondary text-white btn-block btn-pay">Efetuar pagamento</button>
                                                        </div>
                                                    )}

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className="col-md-3 p-0 info-box">
                        <div className="scholarship-info-header bg-primary p-2 px-2">
                            <span className="text-white">Informações importantes</span>
                        </div>
                        <div className="p-3">

                            <span className="d-block"><b>Quem pode ganhar a bolsa?</b></span>
                            <span className="d-block"><b>•</b> Novos alunos ingressantes.</span>
                            <span className="d-block"><b>Quem não pode ganhar a bolsa?</b></span>
                            <span className="d-block"><b>•</b> Alunos que tenham vínculo com a instituição de ensino;</span>
                            <span className="d-block"><b>•</b> Alunos com menos de 06 (seis) meses sem estudar, independente da instituição que estavam matriculados.</span>
                            <span className="d-block"><b>Conheça as características da bolsa:</b></span>
                            <span className="d-block"><b>•</b> O percentual de desconto adquirido por meio do programa é válido até o final do curso, independente de reajustes feitos pela instituição nos valores das mensalidades;</span>
                            <span className="d-block"><b>•</b> O valor relativo ao curso oferecido pode ser alterado sem aviso prévio. Caso haja divergência, predomina o informado pela instituição de ensino;</span>
                            <span className="d-block"><b>•</b> A bolsa não é válida para disciplinas reprovadas;</span>
                            <span className="d-block"><b>•</b> A formação de turmas nos cursos ofertados depende do número de vagas existentes no ato da matrícula e é definido pela instituição.</span>
                            <span className="d-block"><b>Em que caso o aluno pode perder a bolsa?</b></span>
                            <span className="d-block"><b>•</b> Ao trancar ou cancelar a matrícula;</span>
                            <span className="d-block"><b>•</b> Transferir o curso, turno ou instituição;</span>
                            <span className="d-block"><b>•</b> Caso não efetue o pagamento na data do vencimento ou esteja inadimplente, o desconto será suspenso até a sua regularização.</span>
                        </div>
                    </div>
                    <ConfirmModal
                        title="ATENÇÃO"
                        text="Antes de gerar um novo boleto, confira se você já não tem um boleto criado, você pode verificar os seus boletos no seu painel."
                        show={this.state.boletoConfirmModalOpen}
                        confirm={this.confirmBoleto}
                        onHide={() => { this.setState({ boletoConfirmModalOpen: !this.state.boletoConfirmModalOpen }) }}
                    />
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
